import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const NikkeGuidesLevelingResPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Level requirements</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_level.png"
            alt="Level requirements"
          />
        </div>
        <div className="page-details">
          <h1>Level requirements</h1>
          <h2>
            Data about the materials you will need to upgrade your characters.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          While during the first few days, leveling will feel easy as you will
          quickly jump from level 1 to around 70-80, once you reach that point
          things will slow down drastically. This guide will give you tips to
          avoid some bottlenecks and also provide with raw data about the amount
          of materials needed to upgrade characters.
        </p>
        <SectionHeader title="Levels 1-200" />
        <p>
          The below table shows how much materials - Credits, Battle Data and
          Core Dust - you need to level 1 character.
        </p>
        <p>
          At levels 10, 20, 40, 60, 80, 100, 120, 140, 160, 180 you will need
          Core Dust to level up further. While initially you will be limited by
          Credits and Battle Data,{' '}
          <strong>
            starting from level 100, Core Dust will become the resource you will
            lack the most and in events you should prioritize obtaining boxes
            that give Core Dust.
          </strong>
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Credits </th>
              <th>Battle Data </th>
              <th>Core Dust</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2</td>
              <td>600</td>
              <td>2280</td>
              <td>0</td>
            </tr>
            <tr>
              <td>3</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>4</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>5</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>6</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>7</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>8</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>9</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>10</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>11</td>
              <td>600</td>
              <td>2280</td>
              <td>20</td>
            </tr>
            <tr>
              <td>12</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>13</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>14</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>15</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>16</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>17</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>18</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>19</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>20</td>
              <td>600</td>
              <td>2280</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>21</td>
              <td>600</td>
              <td>5700</td>
              <td>40</td>
            </tr>
            <tr>
              <td>22</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>23</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>24</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>25</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>26</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>27</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>28</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>29</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>30</td>
              <td>600</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>31</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>32</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>33</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>34</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>35</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>36</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>37</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>38</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>39</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>40</td>
              <td>840</td>
              <td>5700</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>41</td>
              <td>4860</td>
              <td>22650</td>
              <td>80</td>
            </tr>
            <tr>
              <td>42</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>43</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>44</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>45</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>46</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>47</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>48</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>49</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>50</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>51</td>
              <td>4860</td>
              <td>22650</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>52</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>53</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>54</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>55</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>56</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>57</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>58</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>59</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>60</td>
              <td>6540</td>
              <td>37250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>61</td>
              <td>10350</td>
              <td>71850</td>
              <td>400</td>
            </tr>
            <tr>
              <td>62</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>63</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>64</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>65</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>66</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>67</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>68</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>69</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>70</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>71</td>
              <td>10350</td>
              <td>71850</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>72</td>
              <td>14600</td>
              <td>109450</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>73</td>
              <td>14600</td>
              <td>109450</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>74</td>
              <td>14600</td>
              <td>109450</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>75</td>
              <td>14600</td>
              <td>109450</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>76</td>
              <td>14600</td>
              <td>109450</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>77</td>
              <td>16300</td>
              <td>126250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>78</td>
              <td>16300</td>
              <td>126250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>79</td>
              <td>16300</td>
              <td>126250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>80</td>
              <td>16300</td>
              <td>126250</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>81</td>
              <td>30960</td>
              <td>196380</td>
              <td>1000</td>
            </tr>
            <tr>
              <td>82</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>83</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>84</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>85</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>86</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>87</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>88</td>
              <td>30960</td>
              <td>196380</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>89</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>90</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>91</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>92</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>93</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>94</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>95</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>96</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>97</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>98</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>99</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>100</td>
              <td>41580</td>
              <td>357660</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>101</td>
              <td>63770</td>
              <td>564200</td>
              <td>2000</td>
            </tr>
            <tr>
              <td>102</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>103</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>104</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>105</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>106</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>107</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>108</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>109</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>110</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>111</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>112</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>113</td>
              <td>63770</td>
              <td>564200</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>114</td>
              <td>78190</td>
              <td>738570</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>115</td>
              <td>78190</td>
              <td>738570</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>116</td>
              <td>83230</td>
              <td>813260</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>117</td>
              <td>83230</td>
              <td>813260</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>118</td>
              <td>83230</td>
              <td>813260</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>119</td>
              <td>83230</td>
              <td>813260</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>120</td>
              <td>83230</td>
              <td>813260</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>121</td>
              <td>123040</td>
              <td>1153120</td>
              <td>4500</td>
            </tr>
            <tr>
              <td>122</td>
              <td>123040</td>
              <td>1153120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>123</td>
              <td>123040</td>
              <td>1153120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>124</td>
              <td>169840</td>
              <td>1699120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>125</td>
              <td>169840</td>
              <td>1699120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>126</td>
              <td>169840</td>
              <td>1699120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>127</td>
              <td>169840</td>
              <td>1699120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>128</td>
              <td>169840</td>
              <td>1699120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>129</td>
              <td>169840</td>
              <td>1699120</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>130</td>
              <td>183840</td>
              <td>1789040</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>131</td>
              <td>183840</td>
              <td>1789040</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>132</td>
              <td>185440</td>
              <td>1926400</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>133</td>
              <td>185440</td>
              <td>1926400</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>134</td>
              <td>185440</td>
              <td>1926400</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>135</td>
              <td>185440</td>
              <td>1926400</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>136</td>
              <td>185440</td>
              <td>1926400</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>137</td>
              <td>185440</td>
              <td>1926400</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>138</td>
              <td>200800</td>
              <td>2087360</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>139</td>
              <td>200800</td>
              <td>2087360</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>140</td>
              <td>200800</td>
              <td>2087360</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>141</td>
              <td>235980</td>
              <td>2521980</td>
              <td>7000</td>
            </tr>
            <tr>
              <td>142</td>
              <td>235980</td>
              <td>2521980</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>143</td>
              <td>235980</td>
              <td>2521980</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>144</td>
              <td>235980</td>
              <td>2521980</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>145</td>
              <td>235980</td>
              <td>2521980</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>146</td>
              <td>235980</td>
              <td>2521980</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>147</td>
              <td>256770</td>
              <td>2774070</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>148</td>
              <td>256770</td>
              <td>2774070</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>149</td>
              <td>256770</td>
              <td>2774070</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>150</td>
              <td>256770</td>
              <td>2774070</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>151</td>
              <td>256770</td>
              <td>2774070</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>152</td>
              <td>256770</td>
              <td>2774070</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>153</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>154</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>155</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>156</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>157</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>158</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>159</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>160</td>
              <td>268920</td>
              <td>2944890</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>161</td>
              <td>346500</td>
              <td>3910800</td>
              <td>7000</td>
            </tr>
            <tr>
              <td>162</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>163</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>164</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>165</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>166</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>167</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>168</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>169</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>170</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>171</td>
              <td>346500</td>
              <td>3910800</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>172</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>173</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>174</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>175</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>176</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>177</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>178</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>179</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>180</td>
              <td>368500</td>
              <td>4393300</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>181</td>
              <td>419300</td>
              <td>4988500</td>
              <td>8000</td>
            </tr>
            <tr>
              <td>182</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>183</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>184</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>185</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>186</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>187</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>188</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>189</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>190</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>191</td>
              <td>419300</td>
              <td>4988500</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>192</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>193</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>194</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>195</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>196</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>197</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>198</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>199</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>200</td>
              <td>480500</td>
              <td>5983100</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Levels 1-200 (cumulative)" />
        <p>
          To make it a bit easier to find out how much materials you need,
          here's a table that shows the cumulative values for all materials
          needed for the most important breakpoints.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level range</th>
              <th>Credits</th>
              <th>Battle Data</th>
              <th>Core Dust</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2-11</td>
              <td>6 000</td>
              <td>22 800</td>
              <td>20</td>
            </tr>
            <tr>
              <td>2-21</td>
              <td>12 000</td>
              <td>49 020</td>
              <td>60</td>
            </tr>
            <tr>
              <td>2-41</td>
              <td>30 660</td>
              <td>179 970</td>
              <td>140</td>
            </tr>
            <tr>
              <td>2-61</td>
              <td>148 470</td>
              <td>813 570</td>
              <td>540</td>
            </tr>
            <tr>
              <td>2-81</td>
              <td>421 130</td>
              <td>2 780 700</td>
              <td>1 540</td>
            </tr>
            <tr>
              <td>2-101</td>
              <td>1 200 580</td>
              <td>9 011 480</td>
              <td>3 540</td>
            </tr>
            <tr>
              <td>2-121</td>
              <td>2 661 390</td>
              <td>22 478 440</td>
              <td>8 040</td>
            </tr>
            <tr>
              <td>2-141</td>
              <td>6 245 210</td>
              <td>58 899 940</td>
              <td>15 040</td>
            </tr>
            <tr>
              <td>2-161</td>
              <td>11 463 590</td>
              <td>115 624 180</td>
              <td>22 040</td>
            </tr>
            <tr>
              <td>2-181</td>
              <td>18 664 390</td>
              <td>199 260 380</td>
              <td>30 040</td>
            </tr>
            <tr>
              <td>2-200</td>
              <td>27 181 890</td>
              <td>302 993 280</td>
              <td>30 040</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Beyond 200" />
        <p>
          After you reach level 200, you will have to pay Credits, Battle Data
          and Cores to level up. The resource drain is pretty massive and it
          will take you a long time to even go from 200 to 201.
        </p>
        <p>
          Also, the max level for the Synchro Device will increase with each SSR
          character added to the game, but currently it's capped at 468.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Credits</th>
              <th>Battle Data</th>
              <th>Core Dust</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>201-250</td>
              <td>1,694,200</td>
              <td>20,944,600</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>251-300</td>
              <td>3,134,000</td>
              <td>39,883,600</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>301-350</td>
              <td>3,809,800</td>
              <td>50,478,800</td>
              <td>10,000</td>
            </tr>
            <tr>
              <td>351-400</td>
              <td>4,762,250</td>
              <td>56,536,256</td>
              <td>11,000</td>
            </tr>
            <tr>
              <td>401-450</td>
              <td>5,952,813</td>
              <td>61,624,519</td>
              <td>12,000</td>
            </tr>
            <tr>
              <td>451-500</td>
              <td>7,441,016</td>
              <td>67,170,726</td>
              <td>13,000</td>
            </tr>
            <tr>
              <td>501-550</td>
              <td>8,185,117</td>
              <td>72,544,384</td>
              <td>14,000</td>
            </tr>
            <tr>
              <td>551-600</td>
              <td>9,003,629</td>
              <td>78,347,935</td>
              <td>15,000</td>
            </tr>
            <tr>
              <td>601-650</td>
              <td>9,453,810</td>
              <td>83,832,290</td>
              <td>16,000</td>
            </tr>
            <tr>
              <td>651-700</td>
              <td>9,926,501</td>
              <td>89,700,550</td>
              <td>17,000</td>
            </tr>
            <tr>
              <td>701-750</td>
              <td>10,422,826</td>
              <td>95,082,583</td>
              <td>18,000</td>
            </tr>
            <tr>
              <td>751-800</td>
              <td>10,943,967</td>
              <td>100,787,538</td>
              <td>19,000</td>
            </tr>
            <tr>
              <td>800+</td>
              <td>11,491,166</td>
              <td>105,826,915</td>
              <td>20,000</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesLevelingResPage;

export const Head: React.FC = () => (
  <Seo
    title="Level requirements | NIKKE | Prydwen Institute"
    description="Data about the materials you will need to upgrade your characters."
  />
);
